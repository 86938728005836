import { ServerPaginatedTable, useDropdownApis } from "../../../shared";
import * as React from "react";
import {
  Button,
  Chip,
  createFilterOptions,
  Checkbox,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import InputControl from "../../../shared/components/InputControl";
import { arraySort } from "../../../utils";
import { DashBoardDateRangePicker } from "../../Dashboard";

export const OrderListComponent = (props) => {
  const {
    state,
    globalData,
    filterCollections,
    hasColumnOptions,
    handleApplyFilter,
    HandleFutureOrder,
    handleFilter,
    handleDateChangeApply,
    handleIsOpen,
    onChangeDate,
    handleSearch,
    toggleHiddenManualOrder,
    handleSort,
    setPageSize,
    gotoPage,
    handleFilterClear,
    toggleColumns,
    orderColumns,
    discountOrders,
    discountColumns,
    currentTab,
    type,
  } = props;

  const { pathname } = useLocation();
  const isStoreAdmin = pathname.includes("store-admin");
  const {
    state: { stores },
  } = useDropdownApis({
    isStoreList: !isStoreAdmin ? true : false,
  });

  const { filterApplied, pagination, sortBy, disableApplyButton } = state;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.label,
  });

  const storeOptions = stores.map((v) => {
    return { value: v.id, label: v.name };
  });

  const ActionButtons = ({ id }) => {
    return (
      <div className="action-buttons" onClick={(e) => e.preventDefault()}>
        <Link to={`details/${id}`}>
          <img alt="details" src="/icons/arrow_forward.svg" />
        </Link>
      </div>
    );
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "order_number",
      },
      {
        Header: "Order Date",
        accessor: "order_date",
        Cell: ({ value }) => {
          return (
            <>
              <div
                style={{
                  width: "100px",
                  wordBreak: "break-word",
                }}
              >
                {value}
              </div>
            </>
          );
        },
      },
      {
        Header: "Provider",
        accessor: "channel_name",
        Cell: ({ value, row }) => {
          let userName =
            row.original.finalised_by_user ?? row.original.user_name;
          return (
            <div>
              {value}
              {userName ? `- ${userName}` : null}
            </div>
          );
        },
      },
      {
        Header: "Order Type",
        accessor: "sales_type",
        Cell: ({ row }) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {
                globalData.sale_type.find(
                  (e) => e.value === row.original.sales_type_id
                )?.label
              }
            </span>
          );
        },
      },
      {
        Header: "Store",
        accessor: "store_name",
      },
      {
        Header: "Customer Name",
        id: "customer_first_name",
        accessor: (row) => {
          return (
            <>
              <div>{row.customer.name}</div>
              <Typography variant="caption">{row.customer?.email}</Typography>
            </>
          );
        },
      },
      {
        Header: "Order Total",
        accessor: "grand_total",
        Cell: ({ row }) => {
          return (
            <>
              <span>
                $
                {row.original.grand_total
                  ? row.original.grand_total.toFixed(2)
                  : 0}
              </span>
            </>
          );
        },
      },
      {
        Header: "Discount Amount",
        accessor: "total_discount",
        Cell: ({ row }) => {
          return (
            <span>
              $
              {row.original.total_discount
                ? row.original.total_discount.toFixed(2)
                : 0}
            </span>
          );
        },
      },
      {
        Header: "Order Status",
        accessor: "status",
        Cell: ({ row }) => {
          return (
            <Chip
              label={
                globalData.order_statues.find(
                  (e) => e.value === row.original.status_id
                )?.label
              }
              color={row.original.status_id <= 4 ? "success" : "error"}
              variant="outlined"
              size="small"
              sx={{
                border: "none !important",
              }}
            />
          );
        },
      },
      {
        Header: "Payment Media",
        accessor: "payment_type",
      },
      {
        Header: "Terminal Name",
        accessor: "terminal_name",
      },
      {
        Header: "Action",
        id: "action",
        align: "right",
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons id={row.id} />;
        },
      },
    ],
    [state.orders]
  );
  const tablecolumns = isStoreAdmin
    ? columns.filter((v) => v.Header !== "Store")
    : columns;

  return (
    <>
      <ServerPaginatedTable
        columns={tablecolumns}
        data={state.orders || []}
        key={sortBy}
        isLoading={state?.isBusy}
        filterApplied={filterCollections}
        drawerOpen={hasColumnOptions}
        columnOptions={discountOrders ? discountColumns : orderColumns}
        defaultSearch={false}
        drawerOnClose={() => toggleColumns()}
        onFilterClear={(e) => handleFilterClear("single", e)}
        onAllFilterClear={(e) => handleFilterClear("all")}
        gotoPage={gotoPage}
        setPageSize={setPageSize}
        pagination={pagination}
        handleSort={handleSort}
        sortByGlobal={sortBy}
        toggleHiddenManual={toggleHiddenManualOrder}
        filterContent={
          <InputControl
            type="table-search"
            value={filterApplied.searchText}
            onChange={(e) => handleSearch(e.target.value)}
            onClear={() => handleSearch("")}
          />
        }
        filterSection={
          <>
            <Stack
              direction="row"
              gap="16px"
              alignItems="center"
              flexWrap="wrap"
            >
              <DashBoardDateRangePicker
                onChangeDate={onChangeDate}
                isList={true}
                range={state.date}
                showRange={state.showDate}
                isOpen={state.isCalenderOpen}
                handleIsOpen={handleIsOpen}
                handleDateChangeApply={handleDateChangeApply}
                position="left"
                hasFutureDate={true}
              />
              {currentTab === "1" ? (
                <InputControl
                  type="dropdown"
                  name="Status"
                  label="Status"
                  multiple={true}
                  options={globalData.order_statues ?? []}
                  value={filterApplied.status}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "status")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      placeholder="Status"
                    />
                  )}
                />
              ) : (
                ""
              )}
              {!isStoreAdmin && (
                <InputControl
                  type="dropdown"
                  name="Store"
                  label="Store"
                  multiple={true}
                  options={arraySort(storeOptions || [], "label")}
                  value={filterApplied.stores}
                  disableCloseOnSelect
                  disableClearable={true}
                  onChange={(e, v) => handleFilter(e, v, "store")}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => <></>)
                  }
                  renderOption={(props, option, { selected }) => (
                    <>
                      <li {...props} key={option.value}>
                        <Checkbox
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    </>
                  )}
                  filterOptions={filterOptions}
                  sx={{ width: 250 }}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: "fit-content",
                        minWidth: 250,
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Store" placeholder="Store" />
                  )}
                />
              )}
              <InputControl
                type="dropdown"
                name="Type"
                label="Type"
                multiple={true}
                options={globalData.sale_type ?? []}
                value={filterApplied.type}
                disableCloseOnSelect
                disableClearable={true}
                onChange={(e, v) => handleFilter(e, v, "type")}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <></>)
                }
                renderOption={(props, option, { selected }) => (
                  <>
                    <li {...props} key={option.value}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.label}
                    </li>
                  </>
                )}
                filterOptions={filterOptions}
                sx={{ width: 190 }}
                componentsProps={{
                  paper: {
                    sx: {
                      width: "fit-content",
                      minWidth: 190,
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Type" placeholder="Type" />
                )}
              />
              <InputControl
                type="dropdown"
                disableClearable
                options={globalData?.sale_channels ?? []}
                id="disable-close-on-select"
                disableCloseOnSelect
                multiple={true}
                filterOptions={filterOptions}
                value={filterApplied.channel}
                onChange={(e, v) => handleFilter(e, v, "channel")}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => <></>)
                }
                renderOption={(props, option, { selected }) => (
                  <>
                    <li {...props} key={option.value}>
                      <Checkbox style={{ marginRight: 8 }} checked={selected} />
                      {option.label}
                    </li>
                  </>
                )}
                sx={{ width: 250 }}
                componentsProps={{
                  paper: {
                    sx: {
                      width: "fit-content",
                      minWidth: 250,
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sales Channel"
                    placeholder="Sales Channel"
                  />
                )}
              />
            </Stack>
            <Stack direction="row">
              <Typography
                minWidth="110px"
                fontWeight={400}
                fontSize="16px"
                mt={1}
              >
                Future Orders
              </Typography>
              <InputControl
                type="switch"
                checked={
                  state?.filterApplied?.feature_order === 1 ? true : false
                }
                onChange={() => {
                  HandleFutureOrder(
                    state?.filterApplied?.feature_order === 1 ? 0 : 1
                  );
                }}
              />
              <Button
                type="button"
                size="medium"
                variant="text"
                color="info"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                disabled={disableApplyButton}
                onClick={() => handleApplyFilter()}
              >
                Apply
              </Button>
            </Stack>
          </>
        }
        onRowLink={(e) => {
          return `details/${e.id}`;
        }}
      />
    </>
  );
};
