import * as React from "react";
import { Button, Stack, Card, Typography, Grid, Box } from "@mui/material";
import {
  CustomDetailsList,
  CustomeDialog,
  ResetPasswordDialog,
} from "../../../shared";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { VerifyDialog } from "../..";
import optInPic from "../../../assets/images/Icons/ic_tick_filled_blue.svg";
import optOutPic from "../../../assets/images/Icons/ic_cancel-opt-out.svg";

export const BasicDetailsList = ({
  state,
  id,
  sendEmailVerification,
  sendPhoneVerification,
  handleResetModal,
  handleResetPassword,
  handlePasswordCopy,
  isEditable,
  hasResetPassword,
  globalData,
  handleModal,
  handleOtpInput,
  handleVerifyEmailOrPhone,
  handleForceVerify,
}) => {
  const navigate = useNavigate();
  const { sms, isSmsLoading, email, isEmailLoading, isOpen, otpData } =
    state.verification;
  const columns = [
    {
      title: "Email",
      accessor: (val) => {
        return (
          <Stack direction="row" gap="4px" alignItems="center">
            <Typography variant="subtitle2" fontWeight={400} color="#636363">
              {val.email}
            </Typography>
            {!val.email_verified_at ? (
              <LoadingButton
                color="info"
                sx={{ ml: "6px" }}
                onClick={() => {
                  sendEmailVerification(id);
                }}
                loading={isEmailLoading}
              >
                Verify
              </LoadingButton>
            ) : (
              <img
                style={{ height: "14px" }}
                alt="verify"
                src="/icons/ic_verified_badge.svg"
              />
            )}
          </Stack>
        );
      },
    },
    {
      title: "Phone Number",
      accessor: (val) => {
        return (
          <Stack direction="row" gap="4px" alignItems="center">
            <Typography variant="subtitle2" fontWeight={400} color="#636363">
              {val.phone_formatted}
            </Typography>
            {!val.phone_verified_at ? (
              <LoadingButton
                color="info"
                sx={{ ml: "6px" }}
                onClick={() => {
                  sendPhoneVerification(id);
                }}
                loading={isSmsLoading}
              >
                Verify
              </LoadingButton>
            ) : (
              <img
                style={{ height: "14px" }}
                alt="verify"
                src="/icons/ic_verified_badge.svg"
              />
            )}
          </Stack>
        );
      },
    },
    {
      title: "Date Of Birth",
      accessor: (val) => {
        return (
          <Typography variant="subtitle2" fontWeight={400} color="#636363">
            {val.date_of_birth ?? ""}
          </Typography>
        );
      },
    },
    {
      title: "Gender",
      accessor: (row) => {
        return globalData?.gender?.find((item) => item.value === row.gender)
          ?.label;
      },
    },
    {
      title: "Opt in",
      accessor: (row) => (
        <Box
          component="img"
          src={row.opt_in ? optInPic : optOutPic}
          alt={row.opt_in ? "opt-in-pic" : "opt-out-pic"}
        />
      ),
    },
  ];

  const columns2 = [
    {
      title: "Business Name",
      accessor: "business_name",
    },
    {
      title: "ABN",
      accessor: "abn_number",
    },
    {
      title: "Business Address",
      accessor: (row) => {
        return (
          <Stack direction="row" gap={1}>
            {row?.business_address?.unit_number && (
              <div>{row?.business_address?.unit_number},</div>
            )}
            {row?.business_address?.street_number && (
              <div> {row?.business_address?.street_number},</div>
            )}
            {row?.business_address?.street_name && (
              <div> {row?.business_address?.street_name},</div>
            )}
            {row?.business_address?.suburb && (
              <div> {row?.business_address?.suburb},</div>
            )}
            {row?.business_address?.postcode && (
              <div> {row?.business_address?.postcode},</div>
            )}
            {row?.business_address?.state && (
              <div> {row?.business_address?.state}</div>
            )}
          </Stack>
        );
      },
    },
  ];
  return (
    <>
      <>
        <CustomDetailsList
          columns={columns}
          data={state.data}
          title="Basic Details"
          style={{
            marginBottom: state.data.is_business_user ? "0" : "24px",
            borderRadius: "8px 8px 0px 0",
          }}
          headerRightContent={
            <>
              {isEditable && (
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => navigate(`/customers/edit/${id}`)}
                >
                  Edit
                </Button>
              )}
            </>
          }
        />
        {!!state.data.is_business_user && (
          <>
            <CustomDetailsList
              columns={columns2}
              data={state.data}
              title="Business Details"
              style={{
                borderRadius: "0 0 8px 8px",
              }}
            />
          </>
        )}
        {/* <Card sx={{ p: "32px 24px", mb: "24px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          py="10px"
          width={"100%"}
          gap="20px"
        >
          <Stack>
            <Typography variant="subtitle1" fontWeight={600}>
              Active Status
            </Typography>
          </Stack>
          <Switch defaultChecked />
        </Stack>
      </Card> */}
        {hasResetPassword && (
          <Card sx={{ p: "32px 24px", mb: "24px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  sx={{ width: "150px" }}
                >
                  Reset Password
                </Typography>
              </Grid>
              <Grid item sm={8}>
                <Stack alignItems="flex-end">
                  <Button
                    type="button"
                    size="medium"
                    variant="text"
                    sx={{
                      width: "187px",
                    }}
                    onClick={() => handleResetModal(true, false)}
                  >
                    Reset Password
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
        )}
      </>
      {/* )} */}
      <CustomeDialog
        open={state.resetPassword.isOpen}
        handleClose={() => handleResetModal(false, true)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        size="md"
        PaperProps={{
          sx: {
            width: "423px",
            height: state.resetPassword.slide ? "196px" : "o",
            minHeight: state.resetPassword.slide ? "o" : "290px",
            maxHeight: state.resetPassword.slide ? "o" : "332px",
            p: "24px",
          },
        }}
        title="Reset Password"
        content={
          <ResetPasswordDialog
            data={state.resetPassword}
            handleResetPassword={handleResetPassword}
            handlePasswordCopy={handlePasswordCopy}
          />
        }
      />
      <CustomeDialog
        open={isOpen}
        handleClose={() => handleModal("verify", false, null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "442px", height: "350px", p: "16px" },
        }}
        title={
          otpData.type === "email" ? "Verify Email" : "Verify Mobile Number"
        }
        content={
          <VerifyDialog
            data={state.verification}
            handleOtpInput={handleOtpInput}
            handleVerifyEmailOrPhone={handleVerifyEmailOrPhone}
            handleForceVerify={handleForceVerify}
            state={state.data}
          />
        }
      />
    </>
  );
};
