import {
  ContentLayout,
  CustomDetailsList,
  CustomeDialog,
  DeleteModal,
  HeaderBreadcrumbs,
  Loader,
  ServerPaginatedTable,
  usePermission,
} from "../../../shared";
import * as React from "react";
import { Button, Box, Switch, Stack, Tab, Typography } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PointAdjustment } from "./PointAdjustment";
import { useLoyalty } from "../hooks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { sortItems } from "../../../utils";
import { useEffect } from "react";

export const LoyalityCustomerDetails = () => {
  const { id } = useParams();

  const {
    state,
    handleChangeCustomerStatus,
    togglePointAdjustmentModal,
    handlePointsInputChange,
    onUpdatePointAdjustment,
    handleDeleteModal,
    handleDeleteUserDetails,
    setCurentTab,
    gotoPagePointHistory,
    setPageSizePointHistory,
    handleSortPointHistory,
  } = useLoyalty({ isCusDetails: id });
  const {
    isLoading,
    details,
    isOpen,
    pointsUpdateData,
    isDeleteOpen,
    isVerifyLoading,
  } = state.customers;
  const { pointHistory, pointAdjustmentLoading } = state;
  const navigate = useNavigate();
  const isEditable = usePermission("loyalty-cards-modify");
  const isDeletable = usePermission("loyalty-cards-modify");

  const [value, setValue] = React.useState("1");

  useEffect(() => {
    setCurentTab("loyaltyList", "2");
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const ActionButtons1 = ({ id, type }) => {
    return (
      <>
        {type !== "REDCAT ADJUSTMENT" && (
          <div className="action-buttons" onClick={(e) => e.preventDefault()}>
            <img
              alt="details"
              src="/icons/arrow_forward.svg"
              onClick={() => navigate(`pointTransaction/${id}`)}
            />
          </div>
        )}
      </>
    );
  };
  const column = React.useMemo(
    () => [
      {
        title: "Barcode",
        accessor: "barcode",
      },
      {
        title: "Member Number",
        accessor: "member_number",
      },
      {
        title: "Verification Code",
        accessor: "verification_code",
      },
      {
        title: "Card Type",
        accessor: "loyalty_card_type",
      },
      {
        title: "Status",
        accessor: (row) => {
          return (
            <Switch
              name="status"
              defaultChecked={row.status === 1 ? true : false}
              onChange={() => handleChangeCustomerStatus(id)}
              disabled={!isEditable}
            />
          );
        },
      },
      {
        title: "Customer Verification",
        accessor: (row) => {
          return (
            <>
              {row.customer_verified ? (
                <Stack direction="row" gap="4px" alignItems="center">
                  <img alt="verify" src="/icons/ic_verified_badge.svg" />
                  <Typography
                    variant="subtitle2"
                    lineHeight="17px"
                    fontWeight="400"
                    color="#636363"
                  >
                    Verified
                  </Typography>
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  gap="4px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="subtitle2"
                    lineHeight="17px"
                    fontWeight="400"
                    color="#636363"
                  >
                    Not Verified
                  </Typography>
                  {row.customer_id && (
                    <Link
                      style={{ textDecoration: "none" }}
                      target="_blank"
                      to={`/customers/details/${row.customer_id}`}
                    >
                      <Button>Verify</Button>
                    </Link>
                  )}
                </Stack>
              )}
            </>
          );
        },
      },
    ],
    [isVerifyLoading]
  );

  const columns = React.useMemo(
    () => [
      {
        title: "Points",
        accessor: (row) => {
          return (
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                display="flex"
                columnGap={5}
                alignItems="center"
              >
                {/* Points */}
                <Box component="span">{row.points}</Box>

                {/* Points in dollars */}
                <Box
                  sx={{
                    color: "#C07302",
                    fontWeight: "bold",
                    fontSize: "14px",
                    lineHeight: "16.94px",
                  }}
                >
                  {/* {row.points_in_dollars} */}$
                  {row.points ? (row.points / 100).toFixed(2) : 0.0}
                </Box>
              </Stack>
              <Stack>
                {isEditable &&
                (row.loyalty_card_type_id === 2 ? row.is_owner : true) ? (
                  <Button
                    type="button"
                    size="small"
                    variant="text"
                    onClick={() => togglePointAdjustmentModal(true)}
                  >
                    Point Adjustment
                  </Button>
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
          );
        },
      },
    ],
    []
  );

  const columns1 = React.useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "id",
      },
      {
        Header: "Date & Time",
        accessor: "date_time",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Store Name",
        accessor: "store_name",
        disableGlobalFilter: true,
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Order Number",
        accessor: "order_number",
        disableGlobalFilter: true,
        Cell: ({ value, row }) => {
          return (
            <div
              className="table-taxt-and-icon-link"
              onClick={(e) => {
                e.preventDefault();
                navigate(`/orders/details/${row.original.order_id}`);
              }}
            >
              <span className="link-text">{value}</span>
            </div>
          );
        },
        // disableSortBy: true,
      },
      {
        Header: "Type",
        accessor: "type",
        disableGlobalFilter: true,
        Cell: ({ value }) => {
          return (
            <span style={{ textTransform: "capitalize" }}>
              {value?.toLowerCase()}
            </span>
          );
        },
        sortType: (prev, curr, columnId) => {
          return sortItems(prev, curr, columnId);
        },
      },
      {
        Header: "Points Accumulated",
        accessor: "accumulated",
        disableGlobalFilter: true,
      },
      {
        Header: "Points Redeemed",
        accessor: "redeemed",
        disableGlobalFilter: true,
      },
      {
        Header: "Purchase Amount",
        accessor: "trans_amount",
        Cell: ({ value }) => {
          return `$${value}`;
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Running Total",
        accessor: "running_total",
        disableGlobalFilter: true,
      },
      {
        Header: "Action",
        id: "action",
        accessor: "id",
        align: "right",
        disableGlobalFilter: true,
        disableSortBy: true,
        accessor: (row, index) => {
          return <ActionButtons1 id={row.id} type={row.type} />;
        },
      },
    ],
    []
  );
  return (
    <>
      <ContentLayout
        title={details.customer_name || ""}
        active={details?.status === 1 ? "Active" : "Inactive"}
        breadcrumbs={
          <>
            <HeaderBreadcrumbs
              links={[
                { name: "Dashboard", href: "/" },
                {
                  name: "Loyalty Cards",
                  href: "/loyalty-cards",
                },
                {
                  name: "Cards",
                  href: `/loyalty-cards?cards`,
                },
                { name: details.customer_name || "" },
              ]}
            />
          </>
        }
        rightContent={
          <>
            {isDeletable && (
              <Button
                type="button"
                size="medium"
                variant="text"
                className="delte"
                sx={{
                  minWidth: "40px",
                  p: "8px",
                }}
                onClick={() => handleDeleteModal(true)}
              >
                <img alt="delete" src="/icons/ic_delete_blue.svg" />
              </Button>
            )}
            <Button
              type="button"
              size="medium"
              variant="contained"
              onClick={() => navigate(`/loyalty-cards/edit/${id}`)}
            >
              Edit
            </Button>
          </>
        }
      >
        <TabContext value={value}>
          <Box
            className="sticky-Tab"
            sx={{ borderBottom: 1, borderColor: "divider", top: "104px" }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Details" value="1" />
              <Tab label="Point Transactions" value="2" />
            </TabList>
          </Box>
          <TabPanel sx={{ px: "0" }} value="1">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <CustomDetailsList
                  columns={column}
                  data={details}
                  title="Basic Details"
                />
                <CustomDetailsList
                  columns={columns}
                  data={details}
                  title="Point Details"
                />
              </>
            )}
          </TabPanel>
          <TabPanel sx={{ px: "0" }} value="2">
            <ServerPaginatedTable
              columns={columns1}
              data={pointHistory.dataList}
              searchBar={false}
              tableTitle="Point Transactions"
              gotoPage={gotoPagePointHistory}
              setPageSize={setPageSizePointHistory}
              pagination={pointHistory.pagination}
              handleSort={handleSortPointHistory}
              sortByGlobal={pointHistory.sortBy}
              onRowLink={(e) => {
                if (e.type !== "REDCAT ADJUSTMENT")
                  return `pointTransaction/${e.id}`;
                return;
              }}
            />
          </TabPanel>
        </TabContext>
      </ContentLayout>
      <CustomeDialog
        open={isOpen}
        handleClose={() => togglePointAdjustmentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: { width: "618px", height: "389px", p: "16px" },
        }}
        title="Point Adjustment"
        content={
          <PointAdjustment
            points={details.points}
            data={pointsUpdateData}
            pointAdjustmentLoading={pointAdjustmentLoading}
            handlePointsInputChange={handlePointsInputChange}
            onUpdatePointAdjustment={onUpdatePointAdjustment}
          />
        }
      />
      <DeleteModal
        open={isDeleteOpen}
        handleClose={() => handleDeleteModal(false)}
        onConfirm={handleDeleteUserDetails}
      />
    </>
  );
};
